@import url("https://fonts.cdnfonts.com/css/helvetica-neue-55");

.FooterWrapper {
    /* border: 1px solid red; */
    color: #A4A4A4;
    padding: 40px 0;
    height:300px
}

.FooterCard {
    /* border: 1px solid red; */
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0px;
    box-sizing: border-box;
    border-radius: 8px;
}

.LeftWrapper {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    width: 50%;
}

.LeftContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.RightContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* width: 30%; */
}

.RightContainer > img {
    margin-bottom: 20px;
    max-width: 100px;
}

.SocialIcons {
    display: flex;
    gap: 10px;
}
.SocialIcons > SocialIcon {
    width: 20px;
    height: 20px; 
}

.gridItem p,
.gridItem a {
    font-family: "Inter";
    /* font-size: 22px; */
    /* line-height: 1.5; */
    color: #A4A4A4;
    margin: 0;
    /* font-weight: 400; */
    text-decoration: none;
    text-align: right;
}

.Copyright {
    /* border-top: 2px solid #A4A4A4; */
    text-align: center;
    padding-top: 20px;
    /* font-size: 18px; */
    color: #A4A4A4;
}

/* Media queries for different screen sizes */
@media (max-width: 1200px) {
    /* .FooterCard {
        flex-direction: column;
        align-items: center;
    }

    .LeftWrapper {
        width: 100%;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    .RightContainer {
        width: 100%;
        margin-top: 20px;
    } */
}

@media (max-width: 768px) {
    .FooterCard {
        /* border: 1px solid red; */
        width: 90%;
        margin: auto;
        flex-direction: column;
        align-items: center;
        padding: 0px;
    }

    .LeftWrapper {
        /* border: 1px solid red; */
        display: flex;
        justify-content: space-evenly;
        gap: 40px;
        width: 100%;
        padding: 30px 0 30px 0;
    }
    .Copyright {
        /* border: 2px solid #A4A4A4; */
        text-align: center;
        padding: 20px;
        /* font-size: 18px; */
        color: #A4A4A4;
    }
}

@media (max-width: 480px) {
    .FooterCard {
        /* border: 1px solid red; */
        width: 90%;
        margin: auto;
        flex-direction: column;
        align-items: center;
        padding: 0px;
    }

    .LeftWrapper {
        /* border: 1px solid red; */
        display: flex;
        justify-content: space-between;
        gap: 40px;
        width: 90%;
        padding: 30px 0 30px 0;
    }
    .RightContainer{
        flex-direction: row;
        width: 90%;
        justify-content: space-between;
    }
    .SocialIcons a{
        width: 30px !important;height: 30px !important;
    }
    .Copyright {
        /* border: 2px solid #A4A4A4; */
        text-align: center;
        padding: 20px;
        /* font-size: 18px; */
        color: #A4A4A4;
    }
}