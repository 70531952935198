/* @import url("https://fonts.cdnfonts.com/css/helvetica-neue-55"); */
@import url('https://fonts.googleapis.com/css2?family=Inter');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.App {
  border:0px solid white;
  min-height: 100vh;
  max-width: 1900px;
  margin: 0rem auto;
}
body, html {
  max-width: 100%;
  /* overflow-x: hidden; */
}