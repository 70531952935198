.topCard {
  /* border:1px solid white; */
  width: 100%;
  /* margin: 1vh 0; */
  color: #ffffff;
}
.data-page-section1-text span br {
  display: none;
}
.FlextopCard {
  position: relative;
  /* border:1px solid yellow; */
  width: 90%;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.FlexCard {
  width: 100%;
  height: 200px;
  margin: 5vh 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.centeredDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}
.centeredDiv > p {
  font-size: 20px;
}

.flexContainer {
  /* border: 1px solid yellow; */
  width: 100%;
  display: flex;
  justify-content: space-between;

  align-items: center;
  /* gap: 30%; */
  margin: auto;
  padding: 100px;
}

.centeredContainer1 {
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  text-align: center;
  margin-top: 10px;
}

.paragraph {
  margin: 0;
  text-decoration: underline;
  font-family: Inter;
  font-size: 20px;
}

.smallArrow {
  max-width: 20px;
  height: auto;
}

/* Media queries for different screen sizes */
@media (max-width: 1200px) {
  .divH2 > h2 > span {
    font-size: 45.67px;
  }
  .FlextopCard > .divH2 {
    border: 0px solid red;
  }
  .divH2 > h2 {
    font-size: 430%;
    text-align: center;
  }
  .divP > p {
    font-size: 200%;
    text-align: center;
  }
  .flexContainer {
    width: 90%;
    margin-left: 0;
    gap: 10%;
  }
  .TopImage {
    width: 80%;
    height: auto;
    padding: 20px;
  }
  .TopImage > img {
    height: 200px;
  }
}

@media (max-width: 768px) {
  .divH2 > h2 > span {
    font-size: 45.67px;
  }
  .FlextopCard > .divH2 {
    margin: auto;
    width: 100%;
  }
  .divH2 > h2 {
    font-size: 200%;
    text-align: center;
  }
  .FlextopCard > .divP {
    margin: 20px 0 0 0;
    width: 100%;
  }
  .divP > p {
    font-size: 100%;
    text-align: center;
  }
  .flexContainer {
    /* border: 1px solid yellow; */
    flex-direction: column;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30%;
    margin: auto;
    padding: 30px;
  }
  .TopImage {
    width: 100%;
    padding: 10px;
  }
  .TopImage > img {
    height: 150px;
  }
}

@media (max-width: 480px) {
  .data-page-section1-text span br {
    display: block;
  }
  .flexContainer {
    margin-top: 90px;
  }
  .divH2 > h2 > span {
    font-size: 45.67px;
  }
  .FlextopCard > .divH2 {
    margin: auto;
    width: 100%;
  }
  .divH2 > h2 {
    font-size: 200%;
    text-align: center;
  }
  .FlextopCard > .divP {
    margin: 20px 0 0 0;
    width: 100%;
  }

  .divP > p {
    font-size: 100%;
    text-align: center;
  }
  .flexContainer {
    /* border: 1px solid yellow; */
    flex-direction: column;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30%;
    margin: auto;
    padding: 30px;
  }
  .paragraph {
    margin: 0;
    text-decoration: underline;
    font-family: Inter;
    font-size: 15px;
  }
  .TopImage {
    width: 100%;
    padding: 5px;
  }
  .TopImage > img {
    height: 100px;
  }
}
