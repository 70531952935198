.topCard {
    /* border: 1px solid white; */
    width: 100%;
    margin-top: 15vh;
    color: #FFFFFF;
    background-color: #0B0B0B;
    padding-top: 70px;
    height: 700px;
}

.FlextopCard {
    /* border: 1px solid yellow; */
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: center;
}

.divH2 {
    height: auto;
    margin: 0;
    width: 100%;
}

.divH2 > h2 > span{
    /* font-size: 52.67px; */
    text-align: left;
    /* line-height: 130%; */
    /* letter-spacing: -3%; */
    color: #FFFFFF;
    /* font-weight: 350; */
    font-family: "Inter";
}

.divH2 > h2 {
    /* font-size: 62.67px; */
    text-align: left;
    /* line-height: 130%; */
    /* letter-spacing: -3%; */
    color: #FFFFFF;
    /* font-weight: 250; */
    font-family: "Inter";
}

.flexContainer {
    /* border: 1px solid yellow; */
    width: 90%;
    padding-top: 70px;
    padding-bottom: 70px;
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 30%;
    margin: auto;
}

.centeredContainer1 {
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    gap: 5px;
    text-align: center;
    margin-top: 10px;
    width: 100%;
}

.paragraph {
    margin: 0;
    text-decoration: underline;
    font-family: Inter;
    /* font-size: 20px; */
}

.smallArrow {
    max-width: 20px;
    height: auto;
}


/* Media queries for different screen sizes */
@media (max-width: 1200px) {
    
    .topCard {
        border: 1px solid #0B0B0B;
        height: auto;
    }
    .divH2 > h2 > span {
        /* font-size: 45.67px; */
        text-align: left;
    }

    .divH2 > h2 {
        /* font-size: 35.67px; */
        text-align: left;
    }

    .flexContainer {
        margin-left: 150px;
        gap: 20%;
    }
}

@media (max-width: 768px) {
    .FlextopCard{
        transform: scale(0.85);
        width: 100%;
        text-align: left;
    }
    .FlextopCard h2, .FlextopCard span{
        text-align: left !important;
    }
    .topCard {
        /* border: 1px solid white; */
        height: auto;
    }
    .divH2 > h2 > span {
        /* font-size: 45.67px; */
        text-align: center;
    }

    .divH2 > h2 {
        /* font-size: 35.67px; */
        text-align: center;
    }

    .flexContainer {
        /* border: 1px solid blue; */
        flex-direction: column;
        align-items: center;
        margin-left: 0;
        gap: 15px;
    }

    .centeredContainer1 {
        justify-content: center;
    }
}

@media (max-width: 480px) {
    .topCard {
        /* border: 1px solid white; */
        height: auto;
    }
    .divH2 > h2 > span {
        /* font-size: 45.67px; */
        text-align: center;
    }

    .divH2 > h2 {
        /* font-size: 35.67px; */
        text-align: center;
    }

    .flexContainer {
        /* border: 1px solid red; */
        flex-direction: column;
        align-items: center;
        margin: auto;
        gap: 10px;
        padding: 30px;
    }

    .centeredContainer1 {
        justify-content: center;
    }

    .smallArrow {
        max-width: 15px;
    }
    .paragraph {
        margin: 0;
        text-decoration: underline;
        font-family: Inter;
        /* font-size: 15px; */
    }
}