.TopImage {
  /* border:1px solid red; */
  width: 100%;
  margin: 70px 0 0 0;
  /* padding: 30px; */
}

.FlextopCard {
  /* border:1px solid yellow; */
  width: 90%;
  display: flex;
  justify-content: center;
  margin: auto;
}

.FlextopCard > .divH2 {
  /* border:1px solid red; */
  height: auto;
  margin: auto;
  width: 80%;
}

.divH2 > h2 {
  margin-top: 70px;
  /* font-size: 430%; */
  font-size: 130px;
  line-height: 100%;
  letter-spacing: -8px;
  text-align: center;
  color: #ffffff;
  font-weight: 400;
  font-family: "Inter";
}
.whyNexPara{
  font-size: 28px;
  line-height: auto;
  /* letter-spacing: -3px; */
  text-align: left;
  color: #c4c4c4;
  font-weight: 300;
  font-family: "Inter";
}

.FlextopCardfluency {
  /* border:1px solid yellow; */
  width: 90%;
  display: flex;
  justify-content: center;
  margin: auto;
}

.FlextopCardfluency > .divH2fluency {
  /* border:1px solid red; */
  height: auto;
  margin: auto;
  width: 80%;
}

.divH2fluency > h2 {
  /* font-size: 430%; */
  font-size: 100px;
  line-height: 130%;
  letter-spacing: -6px;
  text-align: center;
  position: relative;
  color: #c4c4c4;
  font-weight: 100;
  font-family: "Inter";
  top: -40px;
}

.LastCard {
  width: 100%;
  padding: 50px 0;
  /* height:400px; */
}

.Button {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 20%;
  background-color: #ffffff;
  color: #000000;
  border-radius: 30px;
  text-decoration: none;
  border: none;
  letter-spacing: -1px;
  padding: 10px 20px;
  box-shadow: #ffffff 0px 0px 6px -1px, #ffffff 0px 2px 4px -1px;
  /* font-family: "Helvetica Neue"; */
  font-family: Inter;
  font-size: 20px;
  line-height: -6%;
  font-weight: 400;
  height: auto;
  text-align: center;
  align-self: flex-start;
  margin: auto;
}
/* Rough */
.container {
  /* border: 1px solid red; */
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin: auto;
}

.content {
  width: 50%;
  flex: 1;
  padding-left: 20px;
  padding-right: 20px; /* Add spacing between the content and the image */
}

.list {
  display: flex;
  flex-direction: column;
  margin-top: 70px;
}
.list > .item {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #2c2c2c;
  padding-bottom: 20px;
  padding-top: 20px;
}

h2 {
  color: #ffffff;
  /* font-size: 36.67px; */
  margin-bottom: 20px;
  /* line-height: auto; */
  /* letter-spacing: -4%; */
  text-align: left;
  font-family: "Inter";
  /* font-weight: 350; */
}


.item > h4 {
  color: #ffffff;
  margin-bottom: 10px;
  position: relative;
  text-align: left;
}
h4 {
  color: #ffffff;
  margin-bottom: 10px;
  position: relative;
  text-align: left;

  /* font-size: 24px; */
  /* line-height: 137%; */
  /* letter-spacing: 1px; */
  /* font-weight: 300; */
  font-family: "Inter";
}



.item> h4::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #000;
}

.item > h4::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #000;
}
.Imagecontent {
  /* border: 1px solid white; */
  width: 50%;
}

.Imagecontent > img {
  width: 100%;
  height: auto;
}

/* Media queries for different screen sizes */
@media (max-width: 768px) {
  .FlextopCard,
  .FlextopCardfluency {
    flex-direction: column;
    align-items: center;
  }
  .FlextopCard > .divH2,
  .FlextopCardfluency > .divH2fluency {
    width: 100%;
  }
  .divH2 > h2,
  .divH2fluency > h2 {
    font-size: 54px;
    letter-spacing: -4px;
    line-height: 160%;
  }
  .LastCard {
    padding: 30px 0;
  }
  
}

/* Media queries for different screen sizes */
@media (max-width: 768px) {
 
  .container {
    flex-direction: column; /* Change to flex column for small screens */
    align-items: center; /* Center align items for small screens */
  }
  .divH2 h2{
    margin-bottom: 0;
  }

  .content {
    width: 100%;
    padding-right: 0;
    margin-bottom: 20px; /* Add margin at the bottom for small screens */
  }

  .list {
    margin-top: 20px;
  }

  .list > .item {
    /* flex-direction: column; */
    align-items: center; /* Center align items for small screens */
    text-align: center; /* Center align text for small screens */
    padding: 10px 0;
  }

  h2 {
    /* font-size: 45.67px; */
    text-align: center; /* Center align text for small screens */
  }

  
  .whyNexPara{
    /* letter-spacing: -3px; */
    text-align: center;
    color: #c4c4c4;
    font-weight: 300;
    font-family: "Inter";
  }

  h4 {
    /* font-size: 18px; */
    margin-bottom: 5px;
  }
  .Imagecontent {
    /* border: 1px solid white; */
    width: 100%;
  }

  .Imagecontent > img {
    width: 100%;
    height: auto;
  }
}
