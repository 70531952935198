.font-size-heading {
  font-size: 56px;
  letter-spacing: -3px;
  color: #fff;
  font-weight: 400;
}

.font-size-subheader {
  font-size: 30px;
  font-weight: 300;
  letter-spacing: -1px;
}
.font-size-subheading {
  /* opacity: 0.6; */

  font-size: 24px;
  letter-spacing: -1px;
  font-weight: 300;
}

.font-size-link {
  font-size: 20px;
  letter-spacing: -1px;
  font-weight: 400;
}

.font-size-navlink {
  font-size: 18px;
  letter-spacing: -1px;
  font-weight: 400;
}

.font-size-cardheader {
  font-size: 22px;
  letter-spacing: -1px;
  font-weight: 400;
}

.font-size-cardsubheader {
  font-size: 16px;
  letter-spacing: -0.5px;
  font-weight: 400;
}

@media (max-width: 800px) {
  .font-size-heading {
    font-size: 34px;
  }
  .font-size-subheader {
    font-size: 20px;
  }
  .font-size-subheading {
    font-size: 16px;
  }

  .font-size-link {
    font-size: 16px;
  }
  .why-nex-text {
    text-align: left;
    font-size: 30px !important;
  }
  .whynexfluent-img {
    width: 130px;
  }
}
