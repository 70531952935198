.topCardIndustries {
    /* border: 1px solid white; */
 width: 100%;
    margin: 15vh 0;
    color: #FFFFFF;
}

.FlextopCard {
    /* border: 1px solid yellow; */
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: center;
}

.divH2 {
    height: auto;
    margin: 0;
    width: 100%;
}

.divH2 > h2 {
    /* font-size: 52.67px; */
    text-align: left;
    /* line-height: 130%; */
    /* letter-spacing: -3%; */
    color: #FFFFFF;
    /* font-weight: 350; */
    font-family: "Inter";
}

.flexContainer {
    /* border: 1px solid yellow; */
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 30%;
}

.flexItem {
    /* border: 1px solid red; */
    width: 100%;
    height: auto;
    margin: 5vh 0;
}

.flexItem1 > h4 {
    color: #686868;
    /* font-weight: 150; */
    font-family: "Inter";
    /* font-size: 35.67px; */
    text-align: left;
    line-height: auto;
    /* letter-spacing: -5%; */
}

.flexItem2 > h4 {
    color: #FFFFFF;
    /* font-weight: 250; */
    font-family: "Inter";
    /* font-size: 35.67px; */
    text-align: left;
    line-height: auto;
    /* letter-spacing: -8%; */
}

.centeredContainer {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 10px;
    text-align: center;
    margin-top: 50px;
}

.paragraph {
    margin: 0;
    text-decoration: underline;
    font-family: Inter;
    /* font-size: 20px; */
}

.smallArrow {
    max-width: 20px;
    height: auto;
}

/* Data future */
.centeredContainer1 {
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 10px;
    text-align: center;
    margin-top: 10px;
}
.firstH2Tag{
    font-size:65.67px;
    font-weight: 350;
}
.secondH2Tag{
    color: #A6A6A6;
    /* font-size:35.67px; */
}
.thirdH2Tag{
    /* font-size: 35.67px; */
}


/* Media queries for different screen sizes */
@media (max-width: 1200px) {
    .divH2 > h2 {
        /* font-size: 45.67px; */
        text-align: left;
    }

    .flexContainer {
        /* flex-direction: column; */
        gap: 20px;
    }

    .flexItem {
        margin: 3vh 0;
    }

    .flexItem1 > h4, .flexItem2 > h4 {
        /* font-size: 45px; */
    }
    .firstH2Tag{
        /* font-size:45.67px; */
        font-weight: 350;
    }
    .secondH2Tag{
        color: #A6A6A6;
        /* font-size:35.67px; */
    }
     .thirdH2Tag{
        /* font-size: 35.67px; */
        text-align: left;
    }
}

@media (max-width: 768px) {
    .topCardIndustries {
        /* border: 1px solid white; */
        margin: 5vh 0;
    }
    .divH2 > h2 {
        /* font-size: 45.67px; */
        text-align: left;
    }

    .flexContainer {
        display: flex;
        justify-content: space-between;
        /* flex-direction: column; */
        gap: 15px;
        margin-top: 30px;
    }

    .flexItem {
        margin: 2vh 0;
    }

    .flexItem1 > h4, .flexItem2 > h4 {
        /* font-size: 35px; */
    }

    .firstH2Tag{
        /* font-size:45.67px; */
        font-weight: 350;
    }
    .secondH2Tag{
        color: #A6A6A6;
        /* font-size:35.67px; */
    }
    .thirdH2Tag{
        /* font-size: 35.67px; */
        text-align: left;
    }
    .paragraph {
        margin: 0;
        text-decoration: underline;
        font-family: Inter;
        /* font-size: 15px; */
    }
}

@media (max-width: 480px) {
    .topCardIndustries {
        /* border: 1px solid white; */
        margin: 9vh 0;
        margin-top: 8vh;
    }
    .divH2 > h2 {
        /* font-size: 45.67px; */
        text-align: left;
    }

    .flexContainer {
        /* flex-direction: column; */
        /* border: 1px solid red; */
        display: flex;
        justify-content: space-between;
        gap: 10px;
        margin-top: 0px;
    }

    .flexItem {
        margin: 1vh 0;
    }

    .flexItem1 > h4, .flexItem2 > h4 {
        /* font-size: 25px; */
    }

    .centeredContainer {
        margin-top: 20px;
    }

    .smallArrow {
        max-width: 15px;
    }

    .firstH2Tag{
        /* font-size:45.67px; */
        font-weight: 350;
    }
    .secondH2Tag{
        color: #A6A6A6;
        /* font-size:35.67px; */
    }
    .thirdH2Tag{
        /* font-size: 35.67px; */
        text-align: left;
    }
    .paragraph {
        margin: 0;
        text-decoration: underline;
        font-family: Inter;
        font-size: 15px;
    }
}