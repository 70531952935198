.main-page-section1 {
  min-height: 100vh;
}
.main-page-section1-gif {
  width: 100%;
}
.main-page-section1-gif img {
  width: 100%;
  position: relative;
  top: -20px;
}
.main-section-text {
  text-align: center;
  position: absolute;
  /* top: -50px; */
  bottom: 0px;
  width: 100%;
}
.main-section-text > p {
  color: #a2a2a2;
  margin-top: 30px;
  font-size: 19px;
}
/* .main-section-text > h1 {
  font-size: 56px;
  letter-spacing: -3px;
} */
.main-section-text > h3 {
  /* font-size: 30px;
  font-weight: 300; */
  margin-top: 10px;
}
.main-page-section1 {
  background-image: url(./Components/Images/MainGif.gif);
  background-size: contain;
  background-position: top center;
  background-repeat: no-repeat;
  /* background-size: 100% 100%; */
}

.main-section-text h1 span {
  /* // gradient */
  background: linear-gradient(to right, rgb(245, 99, 131), rgb(7, 84, 234)) text;
  letter-spacing: -3.5px;
  -webkit-text-fill-color: transparent;
  font-weight: 500;
}
.main-page-links {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.main-page-links {
  padding: 50px 10%;
  padding-top: 90px;
}
.main-link-item {
  display: flex;
  align-items: center;
  gap: 5px;
}
.main-link-item p {
  font-size: 18px;
  font-weight: 300;
  letter-spacing: -1px;
  color: #a2a2a2;
}
.main-link-item p span {
  color: #fff;
}
.main-link-item {
  cursor: pointer;
}
.decision-page {
  padding-top: 90px;
  min-height: 100vh;
  background-image: url(./Components//Images/NexTopImage.png);
  background-size: contain;
  background-position: center 70%;
  background-repeat: no-repeat;
}
.decision-page-text {
  color: #fff;
}
.decision-page-text {
  max-width: 1000px;
  width: 90%;
  margin-left: auto;
  margin-right: 5%;
  text-align: right;
}
.decision-page-subtext {
  position: absolute;
  bottom: 10%;
  right: 4%;
  width: 60%;
  margin-left: auto;
  color: #fff;
}

.decision-page-text p {
  color: #a2a2a2;
  margin-top: 10px;
}

@media (max-width: 1000px) {
  .decision-page {
    padding-top: 90px;
    min-height: 100vh;
    background-image: url(./Components//Images/NexTopImage.png);
    background-size: 180%;
    background-position: center 70%;
    background-repeat: no-repeat;
  }
  .main-page-section1 {
    background-position: center 20%;
    background-size: 150%;
  }
  .main-section-text {
  }
  .main-link-item p {
    font-size: 15px;
  }
}

@media (max-width: 800px) {
  .main-page-section1 {
    background-position: center 20%;
    background-size: 170%;
  }
  .main-link-item p {
    font-size: 14px;
    letter-spacing: -0.5px;
  }
  .main-link-item {
    gap: 3px;
  }
  .main-page-links {
    padding: 40px 3%;
    margin-top: 50px;
  }
  .main-section-text > h3 {
    padding: 0 25px;
  }
  .main-page-links img {
    width: 20px;
  }
  .main-section-text > p {
    font-size: 16px;
  }
}

@media (max-width: 600px) {
  .decision-page {
    padding-top: 90px;
    min-height: 100vh;
    background-image: url(./Components/Images/decision2.png);
    background-size: contain;
    background-position: center 50%;
    background-repeat: no-repeat;
  }
}
