.topCard {
  /* border:1px solid white; */
  width: 100%;
  /* margin: 15vh 0; */
  /* margin-top: 20vh; */
  color: #ffffff;
  margin-top: 50px;
}
.FlextopCard {
  /* border:1px solid yellow; */
  width: 80%;
  justify-content: center;
  margin: auto;
}

.FlextopCard > .divH2 {
  /* border:1px solid red; */
  height: auto;
  margin: auto;
  width: 100%;
}

.divH3 > h2 {
  /* font-size: 58.67px; */
  text-align: center;
  /* line-height: 130%;
    letter-spacing: -2px; */
  color: #ffffff;
  /* font-weight: 350; */
  font-family: "Inter";
}

.FlextopCard > .divP {
  /* border:1px solid red; */
  height: auto;
  margin: 20px 0 0 0;
  width: 100%;
}

.divP > p {
  /* font-size: 28px; */
  text-align: center;
  /* line-height: 137%; */
  /* letter-spacing: 1px; */
  color: #adadad;
  /* font-weight: 300; */
  font-family: "Inter";
}

.TopImage {
  /* border:1px solid red; */
  width: 100%;
  margin: auto;
  margin-top: 40px;
  padding: 0 30px;
}

.TopImage > img {
  /* border: 1px solid blue; */
  width: 100%;
  height: auto;
  margin: auto;
}

/* Media queries for different screen sizes */
@media (max-width: 1200px) {
  .FlextopCard > .divH2 {
    border: 0px solid red;
  }
  .divH3 > h2 {
    /* font-size: 430%; */
    text-align: center;
  }
  .divP > p {
    /* font-size: 200%; */
    text-align: center;
  }
}

@media (max-width: 768px) {
  .topCard {
    /* border:1px solid white; */
    /* margin-top: 5vh; */
  }
  .FlextopCard > .divH2 {
    margin: auto;
    width: 100%;
  }
  .divH3 > h2 {
    /* font-size: 40px; */
    text-align: center;
  }
  .FlextopCard > .divP {
    margin: 20px 0 0 0;
    width: 100%;
  }
  .divP > p {
    /* font-size: 25px; */
    text-align: center;
  }
}

@media (max-width: 480px) {
  .FlextopCard {
    width: 90%;
  }
  .topCard {
    /* border:1px solid white; */
    /* margin-top: 10vh; */
  }
  .FlextopCard > .divH2 {
    /* border:1px solid red; */
    margin: auto;
    width: 100%;
  }
  .divH3 > h2 {
    /* font-size: 40px; */
    text-align: center;
  }
  .FlextopCard > .divP {
    margin: 20px 0 0 0;
    width: 100%;
  }

  .divP > p {
    /* font-size: 25px; */
    text-align: center;
  }
}
