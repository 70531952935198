.topCard {
  /* border: 1px solid white; */
  width: 100%;
  margin: 15vh 0;
  color: #ffffff;
}

.FlextopCard {
  /* border:1px solid yellow; */
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: center;
}

.divH2 {
  /* border:1px solid red; */
  height: auto;
  margin: 0;
  width: 100%;
}
.divH2 > h2 > span {
  /* font-size: 52.67px; */
  text-align: left;
  /* line-height: 130%; */
  /* letter-spacing: -3%; */
  color: #ffffff;
  /* font-weight: 350; */
  font-family: "Inter";
}

.divH2 > h2 {
  /* font-size: 28.67px; */
  text-align: left;
  /* line-height: 130%; */
  /* letter-spacing: -3%; */
  color: #ffffff;
  /* font-weight: 250; */
  font-family: "Inter";
}

.FlexCard {
  /* border: 1px solid red; */
  width: 90%;
  height: 200px;
  margin: 5vh auto; /* Centering FlexCard horizontally */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.flexItem {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.flexImage {
  max-width: 100%;
  max-height: auto;
  object-fit: contain; /* Ensures the images maintain their aspect ratio */
}

/* FlexCardContainer */
.topCardFlex {
  /* border: 1px solid white; */
  width: 100%;
  margin-bottom: 15vh;
  color: #ffffff;
}

.FlexBox {
  display: flex;
  width: 90%;
  margin: 0 auto;
  justify-content: space-evenly;
  color: #ffffff;
}

.FlexBox > div {
  width: 24%;

  border: 1px solid #313131;
  /* border: 1px solid red; */
  /* margin: auto; */
  background-color: #151515;
  border-radius: 9.3px;
  padding: 16px 23px;
}

.DivH4 {
  width: 100%;
  height: auto;
  margin: auto;
  /* border: 1px solid #313131; */
}

.DivH4 > h4 {
  font-family: "Inter";
  line-height: auto;
  /* letter-spacing: -5%; */
  text-align: left;
  color: #ffffff;
  margin: auto;
  /* font-weight: 350; */
  /* font-size: 18.29px; */
}

.DivP {
  width: 100%;
  height: auto;
  margin: auto;
  /* border: 1px solid #313131; */
  padding: 5% 0 5% 0;
}

.DivP > p {
  font-family: "Inter";
  /* font-size: 14.47px; */
  line-height: auto;
  letter-spacing: -5%;
  text-align: left;
  color: #929292;
  margin: auto;
  font-weight: 400;
}

.Button {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 20%;
  background-color: #ffffff;
  color: #000000;
  border-radius: 30px;
  text-decoration: none;
  border: none;
  letter-spacing: -1px;
  padding: 10px 20px;
  box-shadow: #ffffff 0px 0px 6px -1px, #ffffff 0px 2px 4px -1px;
  font-family: "Inter";
  /* font-family: Inter; */
  font-size: 20px;
  line-height: -6%;
  font-weight: 400;
  height: auto;
  text-align: center;
  align-self: flex-start;
  margin: auto;
  cursor: pointer;
}

/* Media queries for different screen sizes */
@media (max-width: 1200px) {
  .divH2 > h2 > span {
    /* font-size: 65.67px; */
    text-align: left;
  }
  .divH2 > h2 {
    /* font-size: 430%; */
    text-align: left;
  }
  .FlexCard {
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    height: auto;
  }
  .flexItem {
    width: 80%;
    margin-bottom: 20px;
  }
  .flexImage {
    max-width: 80%;
    height: auto;
  }

  /* ?flexcardcontainer */

  .FlexBox {
    flex-direction: column;
    align-items: center;
  }
  .FlexBox > div {
    width: 80%;
    margin-bottom: 20px;
  }
  .Button {
    font-size: 18px;
    padding: 8px 16px;
  }
}

@media (max-width: 768px) {
  .divH2 > h2 > span {
    /* font-size: 45.67px; */
    text-align: left;
  }
  .divH2 > h2 {
    /* font-size: 200%; */
    text-align: left;
  }
  .FlexCard {
    /* border: 1px solid red; */
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    height: auto;
    margin: auto;
    width: 90%;
    display: flex;
  }
  .flexItem {
    width: 100%;
    margin-bottom: 20px;
  }
  .flexImage {
    max-width: 70%;
    height: auto;
  }

  .FlexBox {
    flex-direction: column;
    align-items: center;
  }
  .FlexBox > div {
    width: 90%;
    margin-bottom: 20px;
  }
  .Button {
    font-size: 20px;
    padding: 6px 12px;
  }
}

@media (max-width: 480px) {
    .topCardFlex br{
        display: none;
    }
  .FlexBox > div {
    width: 90%;
  }
  .topCardFlex {
    margin: 6vh 0;
  }
  .topCard {
    margin: 6vh 0;
    margin-top: 10vh;
  }
  .divH2 > h2 > span {
    /* font-size: 45.67px; */
    text-align: left;
  }
  .FlextopCard > .divH2 {
    margin: auto;
    width: 100%;
  }
  .divH2 > h2 {
    /* font-size: 200%; */
    text-align: left;
  }
  .FlexCard {
    /* border: 1px solid red; */
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    height: auto;
    margin: auto;
    width: 90%;
    display: flex;
  }
  .flexItem {
    width: 100%;
    margin-bottom: 20px;
  }
  .flexImage {
    max-width: 90%;
    height: auto;
  }

  .FlexBox {
    flex-direction: column;
    align-items: center;
  }
  .FlexBox > div {
    width: 90%;
    margin-bottom: 20px;
  }
  .Button {
    font-size: 15px;
    padding: 5px 20px;
  }
}
