* {
  box-sizing: border-box;
  margin: 0;
  font-family: "Inter";
}
.nav-link {
  text-decoration: none;
  color: #fff;
}

/* hover menu items */
.links p,
.menu-items p {
  font-size: 22px;
  position: relative;
  cursor: pointer;
  color: #e0e0e0;
}

.links p::before,
.menu-items p::before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 0;
  height: 2px;
  background: #ffffff;
  transition: width 0.3s ease;
}

.links p:hover::before,
.menu-items p:hover::before {
  width: 100%;
}
/* hover menu items */
.nexfluent-nav {
  /* border:1px solid yellow; */
  display: flex;
  z-index: 10;
  width: 100%;
  background-image: linear-gradient(to bottom, #000000,#000000, rgba(0, 0, 0, 0));
  /* background-color: #000000; */
  position: fixed;
  color: #fff;
  padding: 10px 30px 10px 30px;
  /* padding: 10px; */
  justify-content: space-between;
  top: 0;
  cursor: pointer;
}
.links {
  border: 0px solid yellow;
  display: flex;
  align-items: center;
  gap: 20px;
  color: #e0e0e0;
}
.links p {
  font-size: 14px;
}
.nexfluent-nav .links p {
  opacity: 0.8;
}

/* .links button{
  border: 1px solid #FFFFFF;;
  text-decoration: none;
  font-size: 20px;
  background-color: #000;
  color: #FFFFFF;
  border-radius: 5%;
  width: 100px;
} */

.nav-logo  > img {
  width: 100px;
  height: auto;
}
.menu-list {
  position: absolute;
  background-color: #00000033;
  backdrop-filter: blur(5px);
  padding: 20px 40px;
  border: 1px solid #1b1b1b;
  border-radius: 10px;
  right: 10px;
  top: 10px;
}
.menu-items {
  display: flex;
  gap: 20px;
  flex-direction: column;
  text-align: left;
}
.menu-items p {
  font-size: 15px;
  color: #b9b9b9;
  /* margin-bottom: 20px; */
}
.menu-items p:hover {
  text-decoration: underline;
}
.menu-items button {
  background-color: #fff;
  color: #000;
  /* border: 1px solid #ffffff; */
  border: none;
  padding: 5px 0;
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
}
.menu {
  cursor: pointer;
}
.links a {
  font-size: 16px;
  /* color: #b6b6b6; */
}

.links > a:hover,
.links > a:focus {
  text-decoration: underline;
}

.Navbar > .nav-items > a {
  border: 0px solid yellow;
  color: #ffffff;
  font-size: 16px;
  text-decoration: none;
  margin: 15px;
  position: relative;
  opacity: 0.9;
  margin: 0px 70px 0 0px;
}

.Navbar > .nav-items > a:hover {
  opacity: 1;
}

.Navbar > .nav-items > a::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background: #ffffff;
  transition: all 0.45s;
}

.Navbar > .nav-items > a:hover::before {
  width: 100%;
}
.menu-items{
  position: relative;
  padding-top: 40px;
}
.close-icon {
  position: absolute;
  top: -5px;
  left: -15px;
}
.Navbar > .nav-toggle {
  display: none;
}
.menu {
  display: none;
}
@media (max-width: 700px) {
  .nexfluent-nav .links {
    display: none;
  }
  .menu {
    display: block;
  }
  .menu img {
    display: flex;
    align-items: center;
    top: 20px;
    width: 30px;
    /* right: 20px; */
    position: relative;
  }
  .common-cards {
    grid-template-columns: repeat(2, 1fr);
  }
  .Navbar {
    width: 100%;
  }

  .Navbar > .nav-logo {
    /* border: 1px solid red; */
    margin: 0px 0px 0 10px;
    font-weight: 300;
  }
  .Navbar > .nav-logo > img {
    border: 0px solid red;
    width: 70%;
  }

  .Navbar > .nav-items {
    border: 0px solid white;
    position: absolute;
    top: 60px;
    display: flex;
    flex-direction: column;
    /* background: #3b4da7; */
    background-color: #000000;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    transition: all 0.45s;
  }

  .Navbar > .nav-items > a {
    border: 0px solid yellow;
    margin: 30px 0 0 20px;
  }

  .Navbar > .nav-items > a::before {
    background: transparent;
  }

  .Navbar > .nav-items.open {
    transform: translateX(0);
  }

  .Navbar > .nav-toggle {
    display: flex;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 0px solid red;
    margin: 0px 20px 0 0px;
  }

  .nav-toggle > .bar {
    position: relative;
    width: 32px;
    height: 2px;
    background: #ffffff;
    transition: all 0.45s ease-in-out;
  }

  .nav-toggle > .bar::before,
  .nav-toggle > .bar::after {
    content: "";
    position: absolute;
    height: 2px;
    background: #ffffff;
    border-radius: 2px;
    transition: all 0.45s ease-in-out;
  }

  .nav-toggle > .bar::before {
    width: 25px;
    transform: translateY(-8px);
    right: 0;
  }

  .nav-toggle > .bar::after {
    width: 32px;
    transform: translateY(8px);
  }

  .nav-toggle.open > .bar {
    transform: translateX(-40px);
    background: transparent;
  }

  .nav-toggle.open > .bar::before {
    width: 32px;
    transform: rotate(45deg) translate(26px, -26px);
  }

  .nav-toggle.open > .bar::after {
    transform: rotate(-45deg) translate(26px, 26px);
  }
}
