.data-page-section1 {
  color: #fff;
  align-items: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.data-page-section1 {
  padding-top: 120px;
}
.data-page-section1 img {
  margin-bottom: 50px;
  /* height: 500px;position: relative; */
  position: relative;
}
/* .data-page-section1 img {
  position: absolute;
  z-index: -1;
} */
/* .data-page-section1 h1 {
  font-size: 48px;
  font-weight: 400;
  width: 80%;
  margin-top: 400px;
  text-align: center;
  letter-spacing: -1%;
}
.data-page-section1 p {
  font-size: 26px;
  text-align: center;
  width: 90%;
  margin-top: 20px;
  letter-spacing: -1%;
  font-weight: 300;
  color: #b8b8b8;
} */
.data-page-section1 button {
  margin-top: 30px;
  background-color: #fff;
  color: #000;
  font-size: 20px;
  font-weight: 400;
  border-radius: 100px;
  letter-spacing: -0.5px;
  border: none;
  padding: 10px 20px;
}
.tagcloud p {
  /* font-size: 26px; */
  color: #c3c3c3;
  width: 80%;
  margin: 0 auto;
  margin-top: 90px;
}

.tagcloud {
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.tagcloud p span {
  color: #fff;
}

.tagcloud img {
  width: 80%;
  margin: 0 auto;
}
.data-heading {
  color: #fff;
  font-size: 48px;
  font-weight: 500;
  text-align: left;
}
.data-heading span {
  font-weight: 300;
  color: #dedede;
}
.data-section-3 {
  width: 90%;
  margin: 150px auto;
}
.data-section-3 h1 {
  letter-spacing: -2px;
  font-weight: 500;
}
.data-section-3 span {
  font-weight: 400;
  color: #c3c3c3;
}
.data-3-cards {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  gap: 20px;
}
.data-3-card {
  width: 25%;
  border: 1px solid #1e1e1e;
  background-color: #080808;
  /* font-size: 26px; */
  text-align: right;
  color: #fff;
  padding: 15px;
  padding-top: 50px;
  padding-left: 30px;
}
.data-section-4 {
  width: 90%;
  margin: 150px auto;
}
.data-section-4 > p {
  color: #afafaf;
  /* font-size: 20px; */
  text-align: left;
  /* font-weight: 300; */
  /* letter-spacing: 1px; */
  margin-top: 10px;
}
.data-section-4 span {
  font-weight: 300;
  color: #c3c3c3;
  letter-spacing: -1px;
}
.data-page-section1-text p {
  opacity: 0.6;
}
.common-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  color: #fff;
  width: 100%;
  gap: 20px;
  margin-top: 50px;
}
.common-card {
  padding: 15px;
  padding-left: 0;
}
.common-card p {
  letter-spacing: -0.5px;

  /* font-size: 22px; */
  font-weight: 300;
  color: #d9d9d9;
}
.common-card p span {
  font-weight: 400;
  color: #fff;
}
.data-section-last {
  background-color: #0b0b0b;
  color: #fff;
  padding: 50px 6%;
  margin-top: 100px;
}
.data-section-last h1 {
  /* font-size: 48px; */
  /* font-weight: 400; */
  /* letter-spacing: -1px; */
}
.link p {
  align-items: center;
  display: flex;
  gap: 5px;
}
.links-comp {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  margin-top: 49px;
}
.links-comp > div p {
  cursor: pointer;
  text-decoration: underline;
  /* font-size: 20px; */
}
.data-section-5 {
  color: #fff;

  width: 90%;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 50px;
}
.data-fluency-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  margin-top: 40px;
}
.data-fluency-card {
  padding: 20px;
  background-color: #080808;
  border: 1px solid #1e1e1e;
}
.data-fluency-card ul {
  padding-left: 20px;
}
.data-fluency-card ul li {
  color: #909090;
  /* font-size: 20px; */
}
.data-fluency-card p {
  /* font-size: 26px; */
  margin-top: 15px;
}
.data-section-5 h1 {
  /* font-size: 42px;
  font-weight: 400;
  letter-spacing: -0.5px; */
}
.data-section-5 {
  margin-bottom: 100px;
}
.data-page-section1-text p {
  margin-top: 20px;
}

@media (max-width: 800px) {
  .data-page-section1 img {
    width: 80%;
    margin: 0 auto;
  }
  .data-page-section1 p {
    width: 90%;
    margin: 0 auto;
    margin-top: 15px;
    /* font-size: 22px; */
  }
  .data-page-section1 button {
    font-size: 18px;
  }
  .tagcloud p {
    font-size: 20px;
  }
  .data-heading {
    font-size: 36px;
  }
  .data-fluency-card ul li {
    /* font-size: 16px; */
  }
  .data-fluency-card p {
    /* font-size: 22px; */
  }
  .data-section-4 > p {
    /* font-size: 18px; */
  }
  .common-cards {
    margin-top: 20px;
  }
  .common-card p {
    /* font-size: 18px; */
  }
  .data-fluency-cards {
    gap: 20px;
  }
  .data-fluency-card p {
    /* font-size: 20px; */
  }
  .data-3-card {
    font-size: 22px;
  }
  .data-fluency-card ul li {
    /* font-size: 14px; */
  }
  .data-3-cards {
    flex-wrap: wrap;
  }
  .data-3-card {
    width: 45%;
  }
  .data-section-last h1 {
    /* font-size: 36px; */
  }
  .links-comp > div p {
    /* font-size: 16px; */
  }
  .links-comp {
    gap: 14px;
  }
  .common-card p {
    /* font-size: 15px; */
  }
  .common-cards {
    gap: 10px;
  }
}
.data-page-section1 h1 {
  margin: 0 auto;
}
@media (max-width: 600px) {
  .links-comp {
    grid-template-columns: repeat(1, 1fr);
  }
  .data-section-4 .font-size-heading {
    font-size: 30px;
  }
  .data-section-4 h1 > span {
    font-size: 28px;
  }
  .common-card {
    padding-right: 0;
  }

  .tagcloud p {
    font-size: 18px;
    width: 90%;
  }
  .tagcloud p {
    margin-top: 50px;
  }
  .data-section-3 {
    margin: 90px auto;
  }
  .data-3-card {
    font-size: 18px;
  }
  .data-heading {
    font-size: 32px;
  }
  .data-3-card {
    width: 48%;
  }
  .data-section-last h1 {
    /* font-size: 32px; */
  }
  .links-comp > div p {
    gap: 2px;
  }
  .links-comp > div p {
    /* font-size: 14px; */
  }
  .tagcloud p {
    /* font-size: 16px; */
  }
  .data-section-5 h1 {
    font-size: 32px;
  }
}

@media (max-width: 500px) {
  .data-fluency-cards {
    grid-template-columns: repeat(1, 1fr);
  }
  .data-section-4 .data-heading {
    /* font-size: 28px; */
  }
  .data-section-last {
    margin-top: 50px;
  }
  .data-fluency-cards {
    margin-top: 20px;
  }
  .data-section-4 {
    margin: 50px auto;
  }
  .data-section-4 > p {
    /* font-size: 15px; */
  }
  .data-3-card {
    width: 100%;
  }
  .data-heading br {
    display: none;
  }
  .data-3-cards {
    margin-top: 20px;
  }
  .data-page-section1 p {
    /* font-size: 18px; */
  }

  .data-page-section1 button {
    font-size: 16px;
  }
  .data-page-section1 h1 {
    width: 90%;
  }
  .data-page-section1 p {
    letter-spacing: 0px;
  }

  .data-page-section1 img {
    /* transform: scale(1.2); */
  }
  .links-comp > div p {
    gap: 5px;
    justify-content: left;
  }
}
.data-page {
  /* overflow-y: hidden; */
}
.data-page-section1-text {
  text-align: center;
}
