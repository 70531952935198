.topCard {
  /* border: 1px solid white; */
  width: 100%;
  margin: 25vh 0;
  color: #ffffff;
}

.FlextopCard {
  /* border:1px solid yellow; */
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: center;
}

.divH2 {
  /* border:1px solid red; */
  height: auto;
  margin: 0;
  width: 100%;
}

.divH2 > h2 > span {
  /* font-size: 52.67px; */
  text-align: left;
  /* line-height: 130%; */
  /* letter-spacing: -3%; */
  color: #ffffff;
  /* font-weight: 350; */
  font-family: "Inter";
}

.divH2 > h2 {
  /* font-size: 28.67px; */
  text-align: left;
  /* line-height: 130%; */
  /* letter-spacing: -3%; */
  color: #ffffff;
  /* font-weight: 250; */
  font-family: "Inter";
}

/* GRID */
.gridContainer {
  /* border:1px solid red; */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 10px;
  width: 90%;
  margin: auto;
  padding-top: 70px;
}

.gridContainer > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.gridContainer img {
  max-width: 100%;
  height: auto;
  object-fit: contain; /* Ensures the images maintain their aspect ratio */
}

.centeredContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  text-align: center;
}

.paragraph {
  margin: 0;
  text-decoration: underline;
  font-family: Inter;
  /* font-size: 20px; */
}

.smallArrow {
  max-width: 20px;
  height: auto;
}

/* Media queries for different screen sizes */
@media (max-width: 1200px) {
  .divH2 > h2 > span {
    /* font-size: 45.67px; */
    text-align: left;
  }

  .divH2 > h2 {
    font-size: 430%;
    text-align: left;
  }
}

@media (max-width: 768px) {
  .topCard {
    /* border: 1px solid white; */
    width: 100%;
    margin: 5vh 0;
    color: #ffffff;
  }
  .divH2 > h2 > span {
    /* font-size: 45.67px; */
    text-align: left;
  }

  .divH2 > h2 {
    font-size: 200%;
    text-align: left;
  }
  .paragraph {
    margin: 0;
    text-decoration: underline;
    font-family: Inter;
    font-size: 15px;
  }
  .gridContainer {
    /* border:1px solid red; */
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
}

@media (max-width: 480px) {
  .topCard {
    /* border: 1px solid white; */
    width: 100%;
    margin: 5vh 0;
    color: #ffffff;
  }
  .divH2 > h2 > span {
    /* font-size: 45.67px; */
    text-align: left;
  }

  .divH2 > h2 {
    /* font-size: 200%; */
    text-align: left;
  }
  .centeredContainer {
    /* border: 1px solid red; */
    width: 80%;
    margin: auto;
  }
  .paragraph {
    margin: 0;
    text-decoration: underline;
    font-family: Inter;
    font-size: 15px;
  }
  .gridContainer {
    /* border:1px solid red; */
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    padding-top: 20px;
  }
  .topCard {
    margin-top: 90px;
  }
  .gridContainer .font-size-heading {
    font-size: 30px !important;
  }
}
