.topCard {
  /* border: 1px solid white; */
  width: 100%;
  /* margin: 10vh 0; */
  color: #ffffff;
}

.TopImage {
  position: relative;
  text-align: center;
  color: white;
}
.comingSoon {
  width: 80%;
  margin: 10vh auto;
  padding-bottom: 10vh;

}
.Gradient {
  background: linear-gradient(to right, #061536, #03122f, #071021, #000000);
}
.comingSoon img {
  width: 100%;
}

.TopImage > img {
  width: 100%;
  min-height: 100vh;
  /* height: 600px; */
  margin: auto;
}

.TopRight {
  position: absolute;
  top: 16px;
  right: 216px;
}

.TopRight > img {
  width: 300px;
  height: auto;
}

/* Media queries for different screen sizes */
@media (max-width: 1200px) {
  .TopImage > img {

    /* height: 500px; */
  }

  .TopRight {
    top: 10px;
    right: 150px;
  }

  .TopRight > img {
    width: 250px;
  }
}

@media (max-width: 768px) {
  .TopImage > img {
    object-fit: cover;
    /* height: 500px; */
    min-height: unset;
    /* height: 400px; */
  }

  .TopRight {
    top: 8px;
    right: 100px;
  }

  .TopRight > img {
    width: 200px;
  }
}

@media (max-width: 480px) {
  .TopImage > img {
    /* height: 300px; */
    /* height: 300px; */
  }

  .TopRight {
    top: 5px;
    right: 50px;
  }

  .TopRight > img {
    width: 150px;
  }
}
